import React, { useState, useEffect } from 'react';

import { fetchContactSubscriptions } from '../api';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Grid } from '@material-ui/core';

import styles from './mainStyles';
import WithMenu from '../Components/WithMenu';
import SubscriptionCard from '../Components/SubscriptionCard';
import WithUserLoggedIn from '../lib/WithUserLoggedIn';
import Spinner from '../Components/Spinner/index';


async function getSubscriptions(userId, organizationId, setSubscriptionList, setSubscriptionStatus) {
	setSubscriptionStatus('pending');
	try {
		const response = await fetchContactSubscriptions(userId, organizationId);
		setSubscriptionList(response.data.subscriptions)
		setSubscriptionStatus('ready');
	} catch (error) {
		console.log(error);
	}
}

const SubscriptionsPage = ({ classes, history }) => {
	const [ subscriptionList, setSubscriptionList ] = useState([]);
	const [ subscriptionStatus, setSubscriptionStatus ] = useState('pending');

	// Debe haber una forma de sacar userLoggedIn del componente WithUserLoggedIn
	const userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));

	useEffect(() => {
		getSubscriptions(userLoggedIn.id, userLoggedIn.organization.id, setSubscriptionList, setSubscriptionStatus);
	}, [ userLoggedIn.id, userLoggedIn.organization.id ]);

	function logout() {
		localStorage.clear();
		history.push('/login');
	}
	return (
		<>
			<WithUserLoggedIn
				history={history}
			>
				<WithMenu
					history={history}
					title="Suscripciones"
					userLoggedIn={userLoggedIn}
					logout={logout}
				>
					{ subscriptionStatus === 'pending' ? (
						<div
							style={{
								marginTop: '6rem'
							}}
						>
							<Spinner />
						</div>
					) : !subscriptionList.length ? (
						<Card
							style={{
								textAlign: 'center',
								width: '50rem',
								margin: '0 auto',
								background: '#f2f2f2',
								color: '#333'
							}}
						>
							<CardContent>
								No tienes ninguna suscripción
							</CardContent>
						</Card>
					) : (
						<>
							<Grid
								container
							>
								{ subscriptionList.map(subscription => (
									<SubscriptionCard
										key={subscription.id}
										subscription={subscription}
									/>
								))}
							</Grid>
							<div
								className={classes.inputContainer}
								style={{ marginTop: '3rem' }}
							>
								<Button
									variant="contained"
									className={classes.button}
									onClick={() => getSubscriptions(userLoggedIn.id, userLoggedIn.organization.id, setSubscriptionList, setSubscriptionStatus)}
								>
									Refrescar

								</Button>
							</div>
						</>
					)}
				</WithMenu>
			</WithUserLoggedIn>
		</>
	);

};

export default withStyles(styles)(SubscriptionsPage);