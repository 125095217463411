import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { Dialog, DialogContentText, DialogActions, Divider } from '@material-ui/core';
import DefaultNumberFormat from '../utils/DefaultNumberFormat';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		width: '100%',
		borderRadius: 18,
		margin: '2%',
		borderLeft: '50px solid #FE6730',
		flexGrow: 1
	},
	flex: {
		display: 'flex'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12,
		fontWeight: 'bold',
		fontSize: 23
	},
	cover: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		height: '24vh'
	},
	center: {
		justifyContent: 'center'
	},
	inputContainer: {
		marginBottom: '3%',
		width: 200
	},
	button: {
		background: `linear-gradient(to right, #feb940, #f4085f)`,
		width: '100%',
		borderRadius: '15px',
		height: 40,
		marginTop: '10%',
		color: 'white',
		fontFamily: 'Noto Sans',
		fontSize: 18,
		fontWeight: 700,
		textTransform: 'none',
		display: 'inline-block'
	},
	end: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	modal: {
		padding: '4rem 1rem 2rem',
		width: '40rem'
	},
	dialogActions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: '2rem',
		'& button': {
			color: 'white',
			fontFamily: 'Noto Sans',
			fontSize: 16,
			textTransform: 'none',
			width: '20rem',
			borderRadius: '1rem',
			background: `#1b2435 !important`,
			'&:hover': {
				filter: 'brightness(1.5)'
			}
		}
	}
});

const handleIntervalData = plan => {
	const { interval, intervalCount } = plan;

	switch(interval) {
	case 'YEAR':
		return `${intervalCount === 1 ? 'Todos los' : `Cada ${intervalCount}`} años`;
	case 'MONTH':
		return `${intervalCount === 1 ? 'Todos los' : `Cada ${intervalCount}`} meses`;
	case 'WEEK':
		return `${intervalCount === 1 ? 'Todas las' : `Cada ${intervalCount}`} semanas`;
	case 'DAY':
		return `${intervalCount === 1 ? 'Todos los' : `Cada ${intervalCount}`} días`;
	}
}

export default function SimpleCard({ subscription }) {
	const classes = useStyles();
	const [ { modalOpened, modalContent, modalButtons } ] = useState({
		modalOpened: false,
		modalContent: '',
		modalButtons: []
	});

	const formatIsoToFriendlyDate = date => {
		if (date) {
			return date.split('-').reverse().join('/')
		}
		return date
	}

	return (
		<Grid
			container
			item
			xs={12}
			sm={6}
		>
			<Card
				className={classes.root}
			>
				<div
					style={{ margin: '3%' }}
				>
					<Grid
						container
						className={classes.flex}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								style={{ marginBottom: '2rem' }}
							>
								<Typography
									variant="h4"
								>
									{`Suscripción N° ${subscription.id}`}
								</Typography>
								<Divider
									style={{ marginBottom: '1rem' }}
								/>
								<Typography>
									<strong>Fechas:</strong>
									<br />
										&nbsp;&nbsp;&nbsp;
									{`Inicio: ${formatIsoToFriendlyDate(subscription.subscriptionConfiguration.startDate)}`}
									<br />
										&nbsp;&nbsp;&nbsp;
									{`Finalización: ${subscription.subscriptionConfiguration.endDate
										? formatIsoToFriendlyDate(subscription.subscriptionConfiguration.endDate)
										: 'Indefinida'}`}
								</Typography>
								{ subscription.subscriptionConfiguration.periods && (
									<Typography>
										<strong>Períodos:</strong>
										&nbsp;
										{subscription.subscriptionConfiguration.periods}
									</Typography>
								)}
								<Typography>
									<strong>Facturas:</strong>
									<br />
										&nbsp;&nbsp;&nbsp;
									{`Emitidas: ${subscription.subscriptionRecurrences.length}`}
									<br />
										&nbsp;&nbsp;&nbsp;
									{`Pagadas: ${subscription.invoices.map(invoice => invoice.status.id)
										.filter(status => status === 2)
										.length}`}
								</Typography>
							</Grid>
							<Grid
								style={{ marginBottom: subscription.customFieldsData.length ? '2rem' : 'auto' }}
							>
								<>
									<Typography
										variant="h5"
									>
										Planes
									</Typography>
									<Divider
										style={{ marginBottom: '1rem' }}
									/>
									{subscription.items.map(item => (
										<>
											<Typography
												varaint="h6"
											>
												{`${item.plan.name} (x${item.quantity})`}
											</Typography>
											<Typography>
												<strong>Id:</strong>
												&nbsp;
												{item.plan.id}
											</Typography>
											<Typography>
												<strong>Descripción:</strong>
												&nbsp;
												{item.plan.description}
											</Typography>
											<Typography>
												<strong>Precio:</strong>
												&nbsp;
												<DefaultNumberFormat
													value={item.plan.price}
												/>
											</Typography>
											<Typography>
												<strong>Intervalo:</strong>
												&nbsp;
												{handleIntervalData(item.plan)}
											</Typography>
										</>
									))}
								</>
							</Grid>
							{ Boolean(subscription.customFieldsData.length) && (
								<Grid>
									<Typography
										variant="h5"
									>
										Información Extra
									</Typography>
									<Divider
										style={{ marginBottom: '1rem' }}
									/>
									{ subscription.customFieldsData.map(({ customField, value }, index) => (
										<Typography
											key={`${index + 1} - ${customField.label}`}
										>
											<strong>
												{`${customField.label}:`}
											</strong>
											&nbsp;
											{value}
										</Typography>
									))}
								</Grid>
							)}
						</Grid>
					</Grid>
				</div>
			</Card>
			<Dialog
				open={modalOpened}
				classes={{
					paper: classes.modal
				}}
			>
				<DialogContentText>
					{modalContent}
				</DialogContentText>
				<DialogActions
					classes={{
						root: classes.dialogActions
					}}
				>
					{modalButtons.map(button => (
						<Button
							onClick={button.handler}
						>
							{button.label}
						</Button>
					))}
				</DialogActions>
			</Dialog>
		</Grid>
	);
}