import React from 'react';
import NumberFormat from 'react-number-format';

function DefaultFormatter({ value, style }){
	return (
		<NumberFormat
			value={parseFloat(value)}
			displayType="text"
			style={style}
			decimalSeparator=","
			thousandSeparator="."
			prefix="$"
			decimalScale={2} // TODO: Limitar a dos decimales?
			isNumericString
		/>
	);
}

export default DefaultFormatter;
