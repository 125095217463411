import React from 'react';

export default function WithUserLoggedIn(props){
   
    var userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));
    if(userLoggedIn===null || userLoggedIn===undefined){
        props.history.push('/login');
    }

    
    
    return (
    <> 
    {(userLoggedIn!=null) && (props.children)}  
    </>)
    
}