import axios from 'axios';
import baseURL from '../lib/config';


export async function loginUser(username: string, password: string) {
	try {
		return await axios.post(`${baseURL}self-management/login`, {
			username,
			password
		})
	} catch (ex) {
		console.warn('Error - Login: ', ex);
	}
}

export async function getGroupFromHash(gHash: string) {
	try {
		return await axios.get(`${baseURL}self-management/group`, {
			params: { gHash }
		});
	} catch (ex) {
		console.warn('Error - ContactGroup: ', ex);
	}
}

export async function getContactsByGroupId(groupId: number) {
	try {
		return await axios.get(`${baseURL}self-management/contacts`, {
			params: { groupId }
		});
	} catch (ex) {
		console.warn('Error - Contacts: ', ex);
	}
}

export async function fetchContactInvoices(contactId: number) {
	try {
		return await axios.get(baseURL + "self-management/" + contactId + "/invoices");
	} catch (ex) {
		console.warn('Error - Invoices: ', ex);
	}
}

export async function createCardTransactionRequest(invoiceId: number) {
	try {
		return await axios.post(baseURL + "self-management/card-transaction-request/" + invoiceId);
	} catch (ex) {
		console.warn('Error - CardTransactionRequest: ', ex);
	}
}

export async function fetchContactSubscriptions(contactId: string, organizationId: string) {
	try {
		return await axios.get(baseURL + "self-management/" + contactId + "/subscriptions/" + organizationId);
	} catch (ex) {
		console.warn('Error - Subscriptions: ', ex);
	}
}

export async function loginUserByHashedId(hashedId: string) {
	try {
		return await axios.post(`${baseURL}self-management/login-by-hashed-id/${hashedId}`);
	} catch (ex) {
		console.warn('Error - Login: ', ex);
	}
}
