import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory as history} from 'history';

import LoginPage from './pages/LoginPage';
import InvoicesPage from './pages/InvoicesPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import AnnouncementsPage from './pages/AnouncementsPage'



export default function Routes() {
  return (
    <Router history={history()}>
    <Switch>
      <Route path="/" exact component={LoginPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/invoices" component={InvoicesPage}/>
      <Route path="/subscriptions" component={SubscriptionsPage}/>
      <Route path="/announcements"component={AnnouncementsPage}/>
    </Switch>
</Router>
  );
}