import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import { Grid } from '@material-ui/core';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		paddingBottom: 0
	},
	appBar: {
		transition: theme.transitions.create([ 'margin', 'width' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
			marginBottom: '5%'
		})
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create([ 'margin', 'width' ], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	toolBar: {
		backgroundColor: '#DFDEDE',
		color: 'black'
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	hide: {
		display: 'none'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: -drawerWidth
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0
	},
	bottom: {
		position: 'absolute',
		bottom: 0,
		width: '100%'
	},
	[`@media (max-width: 400px)`]: {
		bottom: {
			position: 'relative',
			bottom: 'auto',
			left: 'auto',
			height: 'auto'
		}
	}
}));

export default function PersistentDrawerLeft(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [ open, setOpen ] = useState(isOpen());

	const { userLoggedIn } = props;

	function isOpen(){
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
			return false;
		} else{
			return true;
		}
	}
	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};


	return (
		<>
			<Grid
				style={{
					width: '100%',
					height: '4rem'
				}}
			/>
			<div className={classes.root}>
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: open
					})}
				>
					<Toolbar className={classes.toolBar}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton, open && classes.hide)}
						>
							<MenuIcon />
						</IconButton>
						<Typography style={{ color: '#FE6730', fontWeight: 'bold' }} variant="h6" noWrap>
							{props.title}
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={open}
					classes={{
						paper: classes.drawerPaper
					}}
				>
					<div className={classes.drawerHeader}>
						<div style={{ margin: '3%', fontWeight: 'bold' }}>
							{props.userLoggedIn.fullName}
						</div>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</IconButton>
					</div>
					<Divider />
					<List>
						{ userLoggedIn.modules.includes('invoices') && (
							<ListItem
								button
								key="Invoices"
								onClick={() => props.history.push('/invoices')}
							>
								<ListItemIcon>
									<DescriptionIcon />
								</ListItemIcon>
								<ListItemText primary="Facturas" />
							</ListItem>
						)}
						{ userLoggedIn.modules.includes('subscriptions') && (
							<ListItem
								button
								key="Subscriptions"
								onClick={() => props.history.push('/subscriptions')}
							>
								<ListItemIcon>
									<MailIcon />
								</ListItemIcon>
								<ListItemText primary="Suscripciones" />
							</ListItem>
						)}
					</List>
					<List className={classes.bottom}>
						<Divider />
						<ListItem button onClick={props.logout}>
							<ListItemIcon><ExitToAppIcon /></ListItemIcon>
							<ListItemText primary="Cerrar Sessión" />
						</ListItem>
					</List>
				</Drawer>
				<main
					className={clsx(classes.content, {
						[classes.contentShift]: open
					})}
				>
					{' '}
					{props.children}
				</main>
			</div>
		</>
	);
}
