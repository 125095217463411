import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import WithMenu from '../Components/WithMenu'
import styles from './mainStyles';




function Announcements(props) {

  //     async function getAnnouncements() {
  //     await axios.get("https://api.zenrise.io/v1/self-management/"+props.userLoggedIn.id+"/invoices",)
  //     (function (response) {
  //         console.log(response);
  //         setInvoices(response.data);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  // }
  function logout() {
    localStorage.clear();
    props.history.push('/login');
  }
  var userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));

  return (
    <>
      <WithMenu title="Comunicados" userLoggedIn={userLoggedIn} logout={logout} />

    </>
  );
}

export default withStyles(styles)(Announcements);