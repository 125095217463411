const Styles = theme => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        fontFamily: theme.typography.primary,
    },
    inputContainer: {
        marginBottom: "3%",
        width: 200,
    },
    input: {
        borderRadius: '15px',
        borderColor: theme.palette.grey[400],
        marginBotton: "3%",
        width: "100%",
        height: 40,

    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: '1px solid #A04077',
                color: '#A04077'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #A04077',
                color: '#A04077'
            },
        },
    },
    button: {
        background: `linear-gradient(to right, #feb940, #f4085f)`,
        width: "100%",
        borderRadius: '15px',
        height: 40, 
        marginTop: "10%", 
        color: "white",
        fontFamily: "Noto Sans",
        fontSize: 18,
        fontWeight: 700,
        textTransform: "none"
    },
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(3),
    //     transition: theme.transitions.create('margin', {
    //       easing: theme.transitions.easing.sharp,
    //       duration: theme.transitions.duration.leavingScreen,
    //     }),
    //     marginLeft: -drawerWidth,
    //   },
    //   contentShift: {
    //     transition: theme.transitions.create('margin', {
    //       easing: theme.transitions.easing.easeOut,
    //       duration: theme.transitions.duration.enteringScreen,
    //     }),
    // }
});

export default Styles;