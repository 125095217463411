import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import styles from './mainStyles';
import ErrorPopUP from '../Components/ErrorPopUP'
import {
	loginUser,
	getGroupFromHash,
	getContactsByGroupId,
	loginUserByHashedId
} from '../api/index.ts';
import { Typography } from '@material-ui/core';

// Logos
import Logo from '../assets/zen-logo-only.png';
import LogoMobile from '../assets/main-logo-color.svg';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Login(props) {

	const { classes } = props;
	const [ group, setGroup ] = useState(null);
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ showPopUp, setShowPopUp ] = useState(false);
	const [ showAccess, setShowAccess ] = useState(false);

	const query = useQuery();
	const gHash = query.get('ghash');
	const cHash = query.get('chash');


	async function doLogin() {
		try {
			const response = await loginUser(username, password);
			localStorage.setItem('userLoggedIn', JSON.stringify(response.data));
			props.history.push('/invoices');
		} catch (error) {
			console.log(error);
			setShowPopUp(true);
			setUsername('');
			setPassword('');
		}
	}


	function isNotValid() {
		if (username === '' || password === '') {
			return true;
		} else {
			return false;
		}
	}

	function isMobile() {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true;
		} else {
			return false;
		}
	}

	useEffect(() => {
		(async () => {
			if (gHash) {
				await getGroupFromHash(gHash)
					.then(async ({ status, data: { group }}) => {
						if (status === 200) {
							await getContactsByGroupId(group.id)
								.then(({ status, data: { data }}) => {
									if (status === 200) {
										setGroup({
											name: group.name,
											organization: group.organization,
											contacts: data
										});
										setShowAccess(true);
									}
								});
						}
					});
			} else if (cHash) {
				try {
					const response = await loginUserByHashedId(cHash);
					localStorage.setItem('userLoggedIn', JSON.stringify(response.data));
					props.history.push('/invoices');
				} catch (error) {
					console.log(error);
					setShowPopUp(true);
					setUsername('');
					setPassword('');
				}
			} else setShowAccess(true);
		})();
	}, [ ]);

	useEffect(() => {
		isNotValid();
	}, [ username, password ]);

	return (
		<>
			{showPopUp === true && (
				<ErrorPopUP
					open="true"
					setShowPopUp={setShowPopUp}
				/>
			)}
			<Grid
				container
				className={classes.root}
			>

				<>
					<Grid
						container
						alignItems="center"
						justify="center"
						item
						xs
					>
						{ isMobile === 'false' ? (
							<img
								alt="logo"
								src={group?.organization?.logo || Logo}
								style={{
									width: !group ? 'auto' : '15rem'
								}}
							/>
						) : (
							<img
								alt="logo"
								src={group?.organization?.logo || LogoMobile}
								style={{
									width: !group ? 'auto' : '15rem'
								}}
							/>
						)}
					</Grid>
				</>

				{ showAccess && !group ? (
					<Grid
						container
						direction="column"
						alignItems="center"
						justify="center"
						item
						xs
					>
						<form noValidate autoComplete="off">
							<div className={classes.inputContainer}>
								<TextField
									value={username}
									className={classes.textField}
									onChange={event => setUsername(event.target.value)}
									id="outlined-basic"
									placeholder="Usuario"
									variant="outlined"
									InputProps={{
										className: classes.input
									}}
								/>
							</div>
							<div className={classes.inputContainer}>
								<TextField
									value={password}
									className={classes.textField}
									InputProps={{
										className: classes.input
									}}
									onChange={event => setPassword(event.target.value)}
									id="outlined-basic"
									placeholder="Contraseña"
									variant="outlined"
									type="password"
								/>
							</div>
						</form>
						<div className={classes.inputContainer}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								disabled={isNotValid()}
								onClick={doLogin}
							>
								Ingresar
							</Button>
						</div>
					</Grid>
				) : showAccess && (
					<Grid
						container
						direction="column"
						alignItems="center"
						justify="center"
						item
						xs
					>
						<Typography
							style={{
								marginBottom: '2rem',
								color: '#333'
							}}
							variant="h6"
						>
							{group?.name}
						</Typography>
						<Select
							options={group.contacts.map(contact => ({
								value: {
									username: contact.username,
									password: contact.password
								},
								label: contact.fullName
							}))}
							styles={{
								input: provided => ({
									...provided,
									width: '10rem'
								}),
								control: (provided, state) => ({
									...provided,
									borderRadius: '1.3rem',
									boxShadow: state.isFocused && 'none',
									borderColor: state.isFocused ? 'orange' : provided.borderColor,
									'&:hover': {
										boxShadow: 'none',
										borderColor: 'orange'
									}

								})
							}}
							onChange={({ value }, { action }) => {
								if (action !== 'select-option') return;
								setUsername(value.username);
								setPassword(value.password);
							}}
							placeholder="Selecciona tu nombre"
							hideSelectedOptions
						/>
						<div className={classes.inputContainer}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								disabled={isNotValid()}
								onClick={doLogin}
							>
								Ingresar
							</Button>
						</div>
					</Grid>
				)}
			</Grid>


		</>
	);
}

export default withStyles(styles)(Login);