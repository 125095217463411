import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	loading: {
		display: 'flex',
		justifyContent: 'center'
	},
	div: {
		width: '1rem',
		height: '1rem',
		margin: '2rem 0.3 rem',
		background: '#fe6730',
		borderRadius: '50%',
		animation: '0.5s $bounce infinite alternate',
		'&:nth-child(2)': {
			animationDelay: '0.1s'
		},
		'&:nth-child(3)': {
			animationDelay: '0.3s'
		}
	},
	'@keyframes bounce': {
		to: {
			opacity: 0.3,
			transform: 'translate3d(0, -1rem, 0)'
		}
	}
});

const Spinner = () => {
	const classes = useStyles();

	return (
		<div className={classes.loading}>
			<div className={classes.div} />
			<div className={classes.div} />
			<div className={classes.div} />
		</div>
	);
}

export default Spinner;
